<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container is-max">
          <h2 class="has-text-centered is-3 title">
            {{ $t('title') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mini">
        <ResetPasswordForm
          ref="resetPasswordForm"
          :show-email-field="true"
          :email="query.email"
          :is-submitting="isSubmitting"
          :error="error"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "title": "Set Initial Password",
  },
  "ja": {
    "title": "初期パスワードの設定",
  }
}
</i18n>

<script>
import { mapActions } from 'vuex';
import ResetPasswordForm from '@/views/user/components/ResetPasswordForm';

export default {
  name: 'SetPassword',
  components: { ResetPasswordForm },
  data() {
    return {
      isSubmitting: false,
      query: {
        token: undefined,
        email: undefined,
      },
      error: undefined,
    };
  },
  created() {
    const { token } = this.$route.query;
    this.query.token = token;
    this.verifyPasswordToken({ token: this.query.token }).then((record) => {
      this.query.email = record.data.email;
    }).catch(() => {
      this.redirectToLogin();
    });
  },
  methods: {
    ...mapActions('user', ['resetPassword', 'verifyPasswordToken']),
    handleSubmit(data) {
      this.error = undefined;
      this.isSubmitting = true;
      const payload = {
        token: this.$route.query.token,
        password: data.password,
      };
      this.resetPassword(payload)
        .then(() => {
          this.isSubmitting = false;
          this.redirectToLogin();
        })
        .catch((err) => {
          this.isSubmitting = false;
          if (err.response.data && err.response.data.error && err.response.data.error.message) {
            this.error = err.response.data.error.message;
          }
        });
    },
    redirectToLogin() {
      this.$router.push({ path: '/login', query: { from: 'set-initial-password' } });
    },
  },
};
</script>
